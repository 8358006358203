import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Col } from "react-bootstrap";
import Image from "components/Image";
import "./SectionHeaderWithImage.scss";

const SectionHeaderWithImage = ({ header, subheader, imageFileName, className, ...restProps }) => {
  const subheaderPart = subheader ? (
    <h3 className="section-subheading text-muted">{subheader}</h3>
  ) : null;

  const imagePart = imageFileName ? (
    <div className="section-header-image">
      <Image
        className="rounded-circle img-fluid"
        fileName={imageFileName}
        // alt={imageAlt || header || subheader}
      />
    </div>
  ) : null;

  return (
    <Col lg={12} className={clsx("section-header", "text-center", className)} {...restProps}>
      <h2 className="section-heading text-uppercase">{header}</h2>
      {imagePart}
      {subheaderPart}
    </Col>
  );
};

SectionHeaderWithImage.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  imageFileName: PropTypes.string,
  className: PropTypes.string,
};

SectionHeaderWithImage.defaultProps = {
  header: "",
  subheader: "",
  imageFileName: "",
  className: null,
};

export default SectionHeaderWithImage;
